<template>
  <li class="nav-item" v-bind:class="{'menu-is-opening menu-open': laporanActive}">
    <a href="#" class="nav-link" @click="laporanActive = !laporanActive">
      <i class="nav-icon fas fa-book-open"></i>
      <p>
        Laporan - Laporan
        <!-- <i class="right fas fa-angle-left"></i> -->
      </p>
    </a>
    <ul class="nav nav-treeview">
      <li class="nav-item pl-2" v-if="props.akses.includes('laporan_pelayanan_klinik_admin') || props.akses.includes('semua')">
        <router-link to="/laporan-pelayanan-klinik-admin" class="nav-link">
          <i class="far fa-circle nav-icon"></i>
          <p class="text-sm">Laporan Pelayanan Klinik</p>
        </router-link>
      </li>
      <li class="nav-item pl-2"  v-if="props.akses.includes('laporan_pelayanan_klinik_staff') || props.akses.includes('staff') ">
        <router-link to="/laporan-pelayanan-klinik-staff" class="nav-link">
          <i class="far fa-circle nav-icon"></i>
          <p class="text-sm">Laporan Pelayanan Klinik</p>
        </router-link>
      </li>
      <li class="nav-item pl-2" v-if="props.akses.includes('laporan_rekam_medis_pasien') || props.akses.includes('semua')">
        <router-link to="/laporan-rekam-medis" class="nav-link">
          <i class="far fa-circle nav-icon"></i>
          <p class="text-sm">Laporan Rekam Medis Pasien</p>
        </router-link>
      </li>
      <li class="nav-item pl-2" v-if="props.akses.includes('laporan_farmasi') || props.akses.includes('semua') && cabang.jenis == 'pusat'">
        <router-link to="/laporan-farmasi" class="nav-link">
          <i class="far fa-circle nav-icon"></i>
          <p class="text-sm">Laporan Farmasi/Obat/Inventori</p>
        </router-link>
      </li>
      <li class="nav-item pl-2" v-if="props.akses.includes('laporan_akuntansi') || props.akses.includes('semua') && cabang.jenis == 'pusat'">
        <router-link to="/laporan-akuntansi" class="nav-link">
          <i class="far fa-circle nav-icon"></i>
          <p class="text-sm">Laporan Akuntansi</p>
        </router-link>
      </li>
      <li class="nav-item pl-2" v-if="props.akses.includes('laporan_hrd') || props.akses.includes('semua') && cabang.jenis == 'pusat'">
        <router-link to="/laporan-hrd" class="nav-link">
          <i class="far fa-circle nav-icon"></i>
          <p class="text-sm">Laporan HRD</p>
        </router-link>
      </li>
      <li class="nav-item pl-2" v-if="props.akses.includes('laporan_jasa_medis') || props.akses.includes('semua') && cabang.jenis == 'pusat'">
        <router-link to="/laporan-jasa-medis" class="nav-link">
          <i class="far fa-circle nav-icon"></i>
          <p class="text-sm">Laporan Jasa Medis</p>
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script setup>
import store from '@/store'
import { ref, defineProps, computed } from 'vue'

const cabang = computed(() => store.getters['auth/cabang'])
const laporanActive = ref(false)

const props = defineProps({
  akses: Array
})
</script>

<!-- <script>
import store from '@/store'
import { computed, ref, onMounted, reactive } from 'vue'

export default{
	setup () {
	  const laporanActive = ref(false)
    const cabang = computed(() => store.getters['auth/cabang'])

    const { credential, menuPusat, menuCabang } = useCredential()

    const get_cabang = async () => {
      if (cabang.value.jenis == 'pusat') {
        menuPusat()
      } else {
        menuCabang()
      }
    }

    onMounted(() => {
      get_cabang()
    })
		
	  return { cabang, credential, laporanActive }
	 }
}

function useCredential () {
  const credential = reactive({
    'pelayanan_klinik': false,
    'rekam_medis_pasien': false,
    'farmasi': false,
    'akuntansi': false,
    'hrd': false,
    'jasa_medis': false
  })

  const menuPusat = () => {
    credential.pelayanan_klinik = true
    credential.rekam_medis_pasien = true
    credential.farmasi = true
    credential.akuntansi = true
    credential.hrd = true
    credential.jasa_medis = true
  }

  const menuCabang = () => {
    credential.pelayanan_klinik = true
    credential.rekam_medis_pasien = true
    credential.farmasi = false
    credential.akuntansi = false
    credential.hrd = false
    credential.jasa_medis = false
  }

  return { credential, menuPusat, menuCabang }
}
  
</script> -->

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>